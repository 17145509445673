<template>
  <tp-modal name="modal-form-chi-hoan-coc" max-width="768px" width="90%">
    <v-card id="modalFormChiHoanCoc">
      <v-card-title class="pb-4">
        <div class="font-weight-bold">
          Phiếu yêu cầu chi hoàn tiền cho khách
        </div>

        <v-spacer></v-spacer>

        <v-btn
          class="ml-2"
          color="primary"
          dark
          depressed
          :loading="
            paymentTransactionStatusRequest.value ===
              'loading-createPaymentTransaction'
          "
          @click="createTransaction"
        >
          Xác nhận
        </v-btn>

        <v-btn class="ml-2" icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5 black--text" fluid>
        <v-form lazy-validation ref="form">
          <v-card class="text-body-1 px-5 py-4" flat>
            <div class="text-body-2">
              <span class="mr-4">Người tạo: {{ user.name }}</span>

              <span class="mr-4"
                >Chi nhánh: {{ branch ? branch.name : "N/A" }}</span
              >

              <span>{{ nowTime }}</span>
            </div>

            <v-divider class="my-4"></v-divider>

            <section>
              <div class="text-h6 font-weight-bold mb-5">Thông tin chi</div>
              <v-row>
                <v-col class="py-0" cols="6">
                  <div class="font-weight-medium mb-2">Số tiền yêu cầu chi</div>
                  <tp-input-price
                    v-model="transaction.amount"
                    :validate="amountValidate"
                  ></tp-input-price>
                </v-col>
                <v-col class="py-0" cols="6">
                  <div class="font-weight-medium mb-2">Chứng từ liên quan</div>
                  <tp-text-field
                    readonly
                    :value="transactionableCode"
                  ></tp-text-field>
                </v-col>
              </v-row>
            </section>

            <section class="mt-2">
              <div class="text-h6 font-weight-bold mb-5">Người nhận</div>
              <v-row>
                <v-col class="py-0" cols="6">
                  <div class="font-weight-medium mb-2">Người nhận</div>
                  <tp-text-field
                    readonly
                    :value="customer.name"
                  ></tp-text-field>
                </v-col>
                <v-col class="d-flex align-end py-0" cols="6">
                  <tp-text-field
                    readonly
                    :value="customer.phone"
                  ></tp-text-field>
                </v-col>
              </v-row>
            </section>

            <v-divider class="my-4"></v-divider>

            <div class="text-body-1 px-0">
              <div class="font-weight-bold mb-2">Ghi chú</div>
              <tp-textarea
                v-model="transaction.note"
                placeholder="Nhập ghi chú"
                rows="2"
              ></tp-textarea>
            </div>
          </v-card>
        </v-form>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import { BANK_ACCOUNT_OBJECT_TYPE, LIST_PAYMENT_KIND } from "@/core/constant";
import { showModalAlertError, showToastActionAlert } from "@/core/composables";
import DateTimeFunction from "@/core/utils/date-time";

const EMPTY_TRANSACTION = {
  branch_id: null,
  transactionable_id: null,
  transactionable_type: null,
  receiverable_id: null,
  receiverable_type: "App\\Models\\User",
  senderable_id: -1,
  senderable_type: null,
  receiver_bank_id: null,
  sender_bank_id: null,
  kind: LIST_PAYMENT_KIND.CHI_HOAN_COC.id,
  note: null,
  amount: null
};

export default {
  props: {
    branch: {
      type: Object
    },
    customer: {
      type: Object
    },
    transactionableCode: {
      type: String,
      required: true
    },
    transactionableId: {
      type: Number,
      required: true
    },
    transactionableType: {
      type: String,
      required: true
    },
    transactionableTypeName: {
      type: String,
      required: true
    },
    moneyToPayCustomer: {
      type: Number,
      default: 0,
      require: true
    }
  },

  data() {
    return {
      transaction: {
        ...EMPTY_TRANSACTION,
        amount: this.moneyToPayCustomer
      }
    };
  },

  computed: {
    amountValidate() {
      // `required|minVal:1|maxVal:${this.order.customer.cong_no}`
      return `required`;
    },

    customerBankAccounts() {
      return this.$store.getters["BANK_ACCOUNT/objectBankAccounts"];
    },

    nowTime() {
      return `${DateTimeFunction.nowTime()} ${DateTimeFunction.today()}`;
    },

    paymentTransactionStatusRequest() {
      return this.$store.getters["PAYMENT_TRANSACTION/statusRequest"];
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },

  created() {
    this.$store.dispatch("BANK_ACCOUNT/getObjectBankAccounts", {
      id: this.customer.id,
      type: BANK_ACCOUNT_OBJECT_TYPE.USER
    });
  },

  methods: {
    closeModal() {
      this.transaction = { ...EMPTY_TRANSACTION };

      this.$refs.form.resetValidation();

      this.$modal.hide({ name: "modal-form-chi-hoan-coc" });
    },

    async createTransaction() {
      const isValid = this.$refs.form.validate();

      if (!isValid) return;

      if (!this.branch.id) {
        showModalAlertError(this, {
          title: "Không thể yêu cầu chi hoàn cọc",
          message: `Có vẻ ${this.transactionableTypeName} chưa có chi nhánh phụ trách. Vui lòng kiểm tra và thử lại.`
        });

        return;
      }

      // Set data for transaction
      this.transaction.branch_id = this.branch.id;
      this.transaction.transactionable_id = this.transactionableId;
      this.transaction.transactionable_code = this.transactionableCode;
      this.transaction.transactionable_type = this.transactionableType;
      this.transaction.receiverable_id = this.customer.id;

      await this.$store.dispatch(
        "PAYMENT_TRANSACTION/createPaymentTransaction",
        [{ ...this.transaction }]
      );

      if (
        this.paymentTransactionStatusRequest.value ===
        "success-createPaymentTransaction"
      ) {
        showToastActionAlert(this, { message: "Đã gửi yêu cầu chi hoàn tiền" });

        if (this.transactionableType === "App\\Models\\Order") {
          await this.$store.dispatch(
            "ORDER/getOrderPaymentTransactions",
            this.transactionableId
          );
        }

        if (this.transactionableType === "App\\Models\\HoaDonBan") {
          await this.$store.dispatch(
            "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
            this.transactionableId
          );
        }

        if (this.transactionableType === "App\\Models\\KhachTraHang") {
          await this.$store.dispatch(
            "CUSTOMER_RETURN/getCustomerReturnById",
            this.transactionableId
          );

          await this.$store.dispatch(
            "CUSTOMER_RETURN/getCustomerReturnTransactions",
            this.transactionableId
          );
        }
        this.closeModal();
      } else if (
        this.paymentTransactionStatusRequest.value ===
        "error-createPaymentTransaction"
      ) {
        showModalAlertError(this, {
          title: "Lỗi tạo yêu cầu chi hoàn tiền",
          message: this.paymentTransactionStatusRequest.message
        });
      }
    },

    async openModalCustomer() {
      await this.$store.dispatch("CUSTOMER/getCustomerById", this.customer.id);

      this.$modal.show({
        name: "modal-customer"
      });
    }
  }
};
</script>
