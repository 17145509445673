var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"datatable text-body-1 px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có phiếu thu nào","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":_vm.customerReturnStatusRequest.value === 'getCustomerReturnTransactions',"loading-text":"Đang tải dữ liệu","items":_vm.customerReturnPaymentTransactions,"item-key":"id"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,index){return _c('tr',{key:item.id,class:{ 'grey lighten-5': index % 2 === 0 }},[_c('td',[_c('div',[_vm._v(_vm._s(_vm._f("formatTime")(item.created_at,"DD/MM/yyyy")))]),_c('div',[_vm._v(_vm._s(_vm._f("formatTime")(item.created_at,"HH:mm")))])]),_c('td',[_c('div',{staticClass:"primary--text tp-btn--text"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"copied-label",on:{"click":function($event){$event.stopPropagation();return _vm.copyToClipboard(item.code, 'modal-order')}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.code)+" ")])]}}],null,true)},[_c('span',[_vm._v("Sao chép")])])],1)]),_c('td',[_vm._v(" "+_vm._s(_vm.getPaymentTypeText(item.type))+" ")]),_c('td',[(item.receiverable)?_c('span',[_vm._v(_vm._s(item.receiverable.name))]):_c('span',[_vm._v("N/A")])]),_c('td',[_vm._v(_vm._s(_vm.getPaymentKindText(item.kind)))]),_c('td',[(item.receiver_bank)?_c('span',[_vm._v(" "+_vm._s(item.receiver_bank.bank_account_label)+" - "+_vm._s(item.receiver_bank.account_bank)+" ")]):_c('span',[_vm._v("N/A")])]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"primary--text tp-btn--text"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.total_amount))+" ")])]}}],null,true)},[_c('div',[_vm._v("Giá trị phiếu: "+_vm._s(_vm._f("formatCurrency")(item.amount)))]),_c('div',[_vm._v(" Phí thanh toán: "+_vm._s(_vm._f("formatCurrency")(item.payment_fee))+" ")])])],1),_c('td',[_c('div',{staticClass:"d-flex"},[_c('v-chip',{staticClass:"mr-2 font-weight-bold",attrs:{"color":_vm.getReceiptStatus(item.status).color,"small":"","outlined":""}},[(_vm.getReceiptStatus(item.status).isLoading)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"size":15,"width":2,"indeterminate":"","color":_vm.getReceiptStatus(item.status).color}}):_vm._e(),_vm._v(" "+_vm._s(item.status_label)+" ")],1),_c('v-tooltip',{attrs:{"bottom":"","content-class":"font-weight-medium"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey","size":"18"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-variant-circle")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getReceiptStatus(item.status).tooltip))])])],1)])])}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }