<template>
  <tp-modal
    v-if="customerReturn"
    name="modal-customer-return"
    max-width="880px"
  >
    <v-card id="modal-customer-return">
      <v-card-title>
        <div class="font-weight-bold">
          Chi tiết phiếu trả #{{ customerReturn.code }}
        </div>

        <v-spacer></v-spacer>

        <v-btn
          v-if="
            customerReturn.need_pay_customer - customerReturn.pay_customer !== 0
          "
          class="rounded-lg mr-2 primary--text"
          color="primary lighten-5"
          depressed
          @click="openModalFormChiHoanCoc"
        >
          Hoàn tiền
        </v-btn>

        <v-btn
          v-if="isPermissionCancel"
          color="red lighten-5 red--text rounded-lg text--accent-2 mr-2"
          depressed
          @click="cancelGoodsReceipt()"
        >
          Hủy phiếu trả
        </v-btn>
        <!-- <v-btn class="mr-1" icon color="primary">
          <v-icon>mdi-printer</v-icon>
        </v-btn> -->
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card flat>
          <v-row class="mx-0">
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Chi nhánh</div>
              <div class="py-1">
                {{ customerReturn.branch && customerReturn.branch.name }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Nhân viên tạo</div>
              <div class="py-1">
                {{
                  customerReturn.created_user &&
                    customerReturn.created_user.name
                }}
              </div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Ngày tạo</div>
              <div class="py-1">{{ customerReturn.created_at }}</div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Chỉnh sửa cuối</div>
              <div class="py-1">{{ customerReturn.updated_at }}</div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Trạng thái</div>
              <div>
                <v-chip
                  class="text-body-2 font-weight-bold px-2"
                  :color="
                    customerReturn.status === 1 ? 'green' : 'red accent-2'
                  "
                  outlined
                  small
                >
                  {{ customerReturn.status === 1 ? "Đã trả" : "Đã hủy" }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mt-5 py-4" flat>
          <v-row class="mx-0">
            <v-col class="px-5 py-0">
              <div class="">
                <span class="font-weight-bold">Khách hàng: </span
                >{{ customerReturn.khachhang && customerReturn.khachhang.name }}
              </div>
            </v-col>
            <v-col class="px-5 py-0">
              <div class="text-center d-flex align-center">
                <span class="font-weight-bold pr-1">SĐT:</span>
                <template
                  v-if="
                    customerReturn.khachhang && customerReturn.khachhang.phone
                  "
                >
                  <span>
                    {{ customerReturn.khachhang.phone | VMask("### ### ####") }}
                  </span>
                  <v-btn color="primary" class="ml-1" icon small>
                    <v-icon
                      size="18px"
                      @click="
                        copyToClipboard(
                          customerReturn.khachhang.phone,
                          'modal-customer-return'
                        )
                      "
                      >mdi-content-copy</v-icon
                    >
                  </v-btn>
                </template>
                <span v-else>N/A</span>
              </div>
            </v-col>
            <v-col class="px-5 py-0">
              <div class="text-center">
                <span class="font-weight-bold">Công nợ: </span>
                <span>{{
                  customerReturn.khachhang
                    ? customerReturn.khachhang.cong_no
                    : 0 | formatCurrency
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <card-return-products-detail></card-return-products-detail>
        <card-sold-products-detail></card-sold-products-detail>
        <card-payment-transactions
          class="mt-5"
          v-if="customerReturnPaymentTransactions.length"
        ></card-payment-transactions>

        <v-card v-if="customerReturn.reason_refund" class="mt-5" flat>
          <div class="amber lighten-5 text-body-1 px-3 py-2">
            <strong>Ghi chú:</strong> {{ customerReturn.reason_refund }}
          </div>
        </v-card>
      </v-container>

      <modal-form-chi-hoan-coc
        :branch="customerReturn.branch"
        :customer="customerReturn.khachhang"
        :transactionable-code="customerReturn.code"
        :transactionable-id="customerReturn.id"
        :money-to-pay-customer="customerReturn.pay_customer"
        transactionable-type="App\Models\KhachTraHang"
        transactionable-type-name="đơn hàng"
      ></modal-form-chi-hoan-coc>
    </v-card>
  </tp-modal>
</template>

<script>
import CardPaymentTransactions from "./components/CardPaymentTransactions.vue";
import CardReturnProductsDetail from "./components/CardReturnProductsDetail";
import CardSoldProductsDetail from "./components/CardSoldProductsDetail";
import ModalFormChiHoanCoc from "@/modules/Sale/components/modal/ModalFormChiHoanCoc";

export default {
  components: {
    CardPaymentTransactions,
    CardReturnProductsDetail,
    CardSoldProductsDetail,
    ModalFormChiHoanCoc
  },
  computed: {
    customerReturn() {
      return this.$store.getters["CUSTOMER_RETURN/customerReturn"];
    },
    customerReturnPaymentTransactions() {
      return this.$store.getters[
        "CUSTOMER_RETURN/customerReturnPaymentTransactions"
      ];
    },
    customerReturnStatusRequest() {
      return this.$store.getters["CUSTOMER_RETURN/statusRequest"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    isPermissionCancel() {
      const { permissions } = this.user;
      return permissions.includes("customer_return.cancel");
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    cancelGoodsReceipt() {
      this.$modal.show({
        name: "modal-cancel",
        payload: {
          title: `<span>Hủy phiếu nhập <strong>${this.customerReturn.code}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          onSubmitHandler: async cancelReason => {
            await this.$store.dispatch("CUSTOMER_RETURN/cancelCustomerReturn", {
              id:
                this.customerReturn && this.customerReturn.id
                  ? this.customerReturn.id
                  : null,
              canceled_reasons: cancelReason
            });
            if (
              this.customerReturnStatusRequest &&
              this.customerReturnStatusRequest.value ===
                "success-createCustomerReturn"
            ) {
              await this.$store.dispatch("EMPLOYEE/getAllEmployees");
              this.$toast.show({
                name: "toast-action-alert",
                payload: {
                  message:
                    "Hủy phiếu " + this.customerReturn.code + " thành công"
                }
              });
            }
            if (
              this.customerReturnStatusRequest &&
              this.customerReturnStatusRequest.value ===
                "error-createCustomerReturn"
            )
              this.$toast.show({
                name: "toast-action-alert",
                payload: {
                  message: "Hủy phiếu " + this.customerReturn.code + " thất bại"
                }
              });
          }
        }
      });
    },

    closeModal() {
      this.$modal.hide({ name: "modal-customer-return" });
    },

    openModalFormChiHoanCoc() {
      this.$modal.show({ name: "modal-form-chi-hoan-coc" });
    }
  }
};
</script>
