var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-b-0 mt-5",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"datatable px-3 py-2",attrs:{"calculate-widths":"","disable-pagination":"","no-data-text":"Không có dữ liệu","no-results-text":"Không tìm thấy kết quả phù hợp","headers":_vm.headers,"hide-default-footer":"","loading":false,"loading-text":"Đang tải dữ liệu","items":_vm.customerReturn.options,"item-key":"id"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.indexOfOptionIds(item.id) + 1)+" ")]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.product && item.product.name)+" ")]),_c('div',{staticClass:"text-subtitle-2 font-weight-bold grey--text text--darken-2"},[_c('span',[_vm._v(_vm._s(item.SKU))]),(item.name)?_c('span',[_vm._v(" - ")]):_vm._e(),_c('span',{staticClass:"font-weight-light tag-p__mb-0",domProps:{"innerHTML":_vm._s(item.name)}})])])]}},{key:"item.serials",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mb-1"},_vm._l((item.serials),function(serial,index){return _c('v-chip',{key:index,staticClass:"font-weight-medium cyan lighten-4 mr-1 mt-1 px-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(serial)+" ")])}),1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.return_price))+" ")]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.serials.length)+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")((item.return_price * item.serials.length)))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }