<template>
  <v-card flat>
    <v-data-table
      class="datatable text-body-1 px-3 py-2"
      calculate-widths
      disable-pagination
      no-data-text="Không có phiếu thu nào"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="headers"
      hide-default-footer
      :loading="
        customerReturnStatusRequest.value === 'getCustomerReturnTransactions'
      "
      loading-text="Đang tải dữ liệu"
      :items="customerReturnPaymentTransactions"
      item-key="id"
    >
      <!-- Start: Body -->
      <template v-slot:body="{ items }">
        <tbody>
          <!-- Start: Items -->
          <tr
            :class="{ 'grey lighten-5': index % 2 === 0 }"
            v-for="(item, index) in items"
            :key="item.id"
          >
            <td>
              <div>{{ item.created_at | formatTime("DD/MM/yyyy") }}</div>
              <div>{{ item.created_at | formatTime("HH:mm") }}</div>
            </td>
            <td>
              <div class="primary--text tp-btn--text">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="copied-label"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="copyToClipboard(item.code, 'modal-order')"
                    >
                      {{ item.code }}
                    </div>
                  </template>
                  <span>Sao chép</span>
                </v-tooltip>
              </div>
            </td>
            <td>
              {{ getPaymentTypeText(item.type) }}
            </td>
            <td>
              <span v-if="item.receiverable">{{ item.receiverable.name }}</span>
              <span v-else>N/A</span>
            </td>
            <td>{{ getPaymentKindText(item.kind) }}</td>
            <td>
              <span v-if="item.receiver_bank">
                {{ item.receiver_bank.bank_account_label }} -
                {{ item.receiver_bank.account_bank }}
              </span>
              <span v-else>N/A</span>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="primary--text tp-btn--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.total_amount | formatCurrency }}
                  </div>
                </template>

                <div>Giá trị phiếu: {{ item.amount | formatCurrency }}</div>
                <div>
                  Phí thanh toán: {{ item.payment_fee | formatCurrency }}
                </div>
              </v-tooltip>
            </td>

            <td>
              <div class="d-flex">
                <v-chip
                  class="mr-2 font-weight-bold"
                  :color="getReceiptStatus(item.status).color"
                  small
                  outlined
                >
                  <v-progress-circular
                    v-if="getReceiptStatus(item.status).isLoading"
                    class="mr-2"
                    :size="15"
                    :width="2"
                    indeterminate
                    :color="getReceiptStatus(item.status).color"
                  ></v-progress-circular>

                  {{ item.status_label }}
                </v-chip>

                <v-tooltip bottom content-class="font-weight-medium">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="grey" size="18" v-bind="attrs" v-on="on"
                      >mdi-information-variant-circle</v-icon
                    >
                  </template>

                  <span>{{ getReceiptStatus(item.status).tooltip }}</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
          <!-- End: Items -->
        </tbody>
      </template>
      <!-- End: Body -->
    </v-data-table>
  </v-card>
</template>

<script>
import { LIST_PAYMENT_STATUS, getReceiptStatus } from "@/core/constant";
import { getPaymentKindText, getPaymentTypeText } from "@/core/composables";
// import moment from "moment";

export default {
  data() {
    return {
      headers: [
        {
          text: "Thời gian",
          align: "start",
          value: "created_at",
          sortable: false
        },
        { text: "Phiếu ghi thu", value: "code", sortable: false },
        { text: "Loại phiếu", value: "type", sortable: false },
        { text: "Đối tượng nhận", value: "receiverable", sortable: false },
        { text: "Phương thức", value: "kind", sortable: false },
        { text: "Tài khoản", value: "receiver_bank", sortable: false },
        { text: "Tổng giá trị phiếu", value: "total_amount", sortable: false },
        {
          text: "Trạng thái",
          align: "center",
          value: "status",
          sortable: false
        }
      ],
      LIST_PAYMENT_STATUS
    };
  },

  // filters: {
  //   formatTime(d, format) {
  //     return moment(d).format(format);
  //   }
  // },

  computed: {
    customerReturnPaymentTransactions() {
      return this.$store.getters[
        "CUSTOMER_RETURN/customerReturnPaymentTransactions"
      ];
    },

    customerReturnStatusRequest() {
      return this.$store.getters["CUSTOMER_RETURN/statusRequest"];
    }
  },

  methods: {
    getPaymentKindText(kind) {
      return getPaymentKindText(kind);
    },

    getPaymentTypeText(type) {
      return getPaymentTypeText(type);
    },

    getReceiptStatus(status) {
      return getReceiptStatus(status);
    }
  }
};
</script>

<style lang="scss" scoped>
.group-total {
  max-width: 100%;
  width: 310px;
  .text-total {
    font-size: 1.125rem;
  }
}
</style>
