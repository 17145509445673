<template>
  <v-card id="modalCustomerReturn_cardSoldProduct" class="rounded-t-0" flat>
    <v-data-table
      v-if="customerReturn.exportGood"
      :headers="headers"
      :items="customerReturn.exportGood.options"
      :loading="false"
      calculate-widths
      class="datatable px-3 py-2"
      disable-pagination
      hide-default-footer
      item-key="id"
      loading-text="Đang tải dữ liệu"
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ indexOfOptionIds(item.id) + 1 }}
      </template>

      <template v-slot:[`item.product`]="{ item }">
        <div class="my-2">
          <div class="font-weight-bold">
            {{ item.product && item.product.name }}
          </div>
          <div
            class="text-subtitle-2 font-weight-bold grey--text text--darken-2"
          >
            <span>{{ item.SKU }}</span>
            <span v-if="item.name"> - </span>
            <span
              class="font-weight-light tag-p__mb-0"
              v-html="item.name"
            ></span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.serials`]="{ item }">
        <div class="mb-1">
          <v-chip
            v-for="(serial, index) in item.serials"
            :key="index"
            class="font-weight-medium cyan lighten-4 mr-1 mt-1 px-2"
            small
          >
            {{ serial }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.serials.length }}
      </template>

      <template v-slot:[`item.unit_sold_price`]="{ item }">
        {{ item.unit_sold_price | formatCurrency }}
      </template>

      <template v-slot:[`item.rebate_amount`]="{ item }">
        {{ (item.unit_sold_price - item.sold_price) | formatCurrency }}
      </template>

      <template v-slot:[`item.sold_price`]="{ item }">
        {{ item.sold_price | formatCurrency }}
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{ (item.sold_price * item.serials.length) | formatCurrency }}
      </template>
    </v-data-table>

    <v-divider class="py-3 mx-3"></v-divider>

    <div class="d-flex justify-end px-6 pb-5">
      <div class="group-total">
        <div
          v-if="customerReturn.hoadonban_origin"
          class="d-flex align-center justify-space-between font-weight-medium mb-1"
        >
          <span class="mr-5">Mã hoá đơn trả: </span>

          <div class="d-flex text-right">
            <v-chip
              color="cyan lighten-5 primary--text"
              label
              small
              @click.stop="
                openModalSaleReceipt(customerReturn.hoadonban_origin.id)
              "
            >
              {{ shortenSaleReceiptCode(customerReturn.hoadonban_origin.code) }}
            </v-chip>
          </div>
        </div>

        <div class="d-flex justify-space-between font-weight-medium">
          <span class="mr-5">Tổng tiền hàng trả: </span>
          <span>{{ customerReturn.goods_value | formatCurrency }}</span>
        </div>
        <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5">Phí trả hàng: </span>
          <span>-{{ customerReturn.phitrahang | formatCurrency }}</span>
        </div>

        <v-divider class="my-2"></v-divider>

        <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5">Tổng tiền phiếu trả: </span>
          <span>{{ customerReturn.value | formatCurrency }}</span>
        </div>

        <div class="d-flex justify-space-between font-weight-medium">
          <span class="mr-5">Tổng tiền hoá đơn mua: </span>
          <span>{{
            customerReturn.hoadonban
              ? customerReturn.hoadonban.value
              : 0 | formatCurrency
          }}</span>
        </div>

        <div v-if="customerReturn.hoadonban">
          <v-chip
            color="cyan lighten-5 primary--text"
            label
            small
            @click.stop="openModalSaleReceipt(customerReturn.hoadonban.id)"
          >
            {{ shortenSaleReceiptCode(customerReturn.hoadonban.code) }}
          </v-chip>
        </div>

        <v-divider class="my-2"></v-divider>

        <div
          class="d-flex justify-space-between font-weight-medium mt-1 font-weight-bold"
        >
          <span class="mr-5">Cần trả khách: </span>
          <span>{{ customerReturn.need_pay_customer | formatCurrency }}</span>
        </div>

        <div class="d-flex justify-space-between font-weight-medium mt-1">
          <span class="mr-5">Đã trả khách: </span>
          <span>{{ customerReturn.pay_customer | formatCurrency }}</span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { getOrderStatusByValue } from "@/modules/Transaction/utils/common";

export default {
  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Sản phẩm mua",
          align: "start",
          sortable: false,
          value: "product"
        },
        {
          text: "Serials",
          align: "start",
          sortable: false,
          value: "serials"
        },
        {
          text: "Số lượng",
          align: "start",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Đơn giá",
          align: "start",
          sortable: false,
          value: "unit_sold_price"
        },
        {
          text: "Chiết khấu",
          align: "start",
          sortable: false,
          value: "rebate_amount"
        },
        {
          text: "Giá sau giảm",
          align: "start",
          sortable: false,
          value: "sold_price"
        },
        {
          text: "Thành tiền",
          align: "start",
          sortable: false,
          value: "total"
        }
      ]
    };
  },
  computed: {
    customerReturn() {
      return this.$store.getters["CUSTOMER_RETURN/customerReturn"];
    },
    optionIds() {
      return this.customerReturn.exportGood.options.map(item => item.id);
    },
    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    },
    returnedGoodsTotal() {
      return this.customerReturn.options.reduce(
        (total, option) => total + option.return_price * option.serials.length,
        0
      );
    },
    returnedTotal() {
      let total = this.returnedGoodsTotal;

      if (this.customerReturn.phitrahang) {
        total -= this.customerReturn.phitrahang;
      }

      return total;
    },

    soldGoodsTotal() {
      if (!this.customerReturn.exportGood) return 0;

      return this.customerReturn.exportGood.options.reduce(
        (total, option) => total + option.sold_price * option.serials.length,
        0
      );
    },
    soldTotal() {
      let total = this.soldGoodsTotal;

      if (this.customerReturn.exportGood) {
        if (this.customerReturn.exportGood.phivanchuyen) {
          total += this.customerReturn.exportGood.phivanchuyen;
        }
        if (this.customerReturn.exportGood.promo) {
          total -= this.customerReturn.exportGood.promo.money;
        }
      }

      return total;
    }
  },
  methods: {
    indexOfOptionIds(value) {
      return this.optionIds.indexOf(value);
    },
    getOrderStatusByValue(value) {
      return getOrderStatusByValue(value);
    },
    shortenSaleReceiptCode(code) {
      return code.split("-")[0];
    },

    async openModalSaleReceipt(id) {
      await this.$store.dispatch("SALE_RECEIPT/getSaleReceiptById", id);

      await this.$store.dispatch(
        "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
        id
      );

      await this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\HoaDonBan",
          modelId: id
        }
      );

      this.$modal.show({
        name: "modal-sale-receipts"
      });
    },

    async openDeliveryBill(id) {
      await this.$store.dispatch("SALE_RECEIPT/getSaleReceiptById", id);

      this.$modal.show({ name: "modal-sale-receipts" });
    }
  }
};
</script>

<style lang="scss" scoped>
.group-total {
  max-width: 100%;
  width: 338px;
  .text-total {
    font-size: 1.125rem;
  }
}
</style>
