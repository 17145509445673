<template>
  <v-card class="rounded-b-0 mt-5" flat>
    <v-data-table
      class="datatable px-3 py-2"
      calculate-widths
      disable-pagination
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="headers"
      hide-default-footer
      :loading="false"
      loading-text="Đang tải dữ liệu"
      :items="customerReturn.options"
      item-key="id"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ indexOfOptionIds(item.id) + 1 }}
      </template>

      <template v-slot:[`item.product`]="{ item }">
        <div class="my-2">
          <div class="font-weight-bold">
            {{ item.product && item.product.name }}
          </div>
          <div
            class="text-subtitle-2 font-weight-bold grey--text text--darken-2"
          >
            <span>{{ item.SKU }}</span>
            <span v-if="item.name"> - </span>
            <span
              class="font-weight-light tag-p__mb-0"
              v-html="item.name"
            ></span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.serials`]="{ item }">
        <div class="mb-1">
          <v-chip
            v-for="(serial, index) in item.serials"
            :key="index"
            class="font-weight-medium cyan lighten-4 mr-1 mt-1 px-2"
            small
          >
            {{ serial }}
          </v-chip>
        </div>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        {{ item.return_price | formatCurrency }}
      </template>

      <template v-slot:[`item.quantity`]="{ item }">
        {{ item.serials.length }}
      </template>

      <template v-slot:[`item.total`]="{ item }">
        {{ (item.return_price * item.serials.length) | formatCurrency }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "STT",
          align: "start",
          sortable: false,
          value: "id"
        },
        {
          text: "Sản phẩm trả",
          align: "start",
          sortable: false,
          value: "product"
        },
        {
          text: "Serials",
          align: "start",
          sortable: false,
          value: "serials"
        },
        {
          text: "Số lượng",
          align: "start",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Giá bán (Trừ CK)",
          align: "start",
          sortable: false,
          value: "price"
        },
        {
          text: "Giá trị trả",
          align: "start",
          sortable: false,
          value: "total"
        }
      ]
    };
  },
  computed: {
    customerReturn() {
      return this.$store.getters["CUSTOMER_RETURN/customerReturn"];
    },
    optionIds() {
      return this.customerReturn.options.map(item => item.id);
    }
  },
  methods: {
    indexOfOptionIds(value) {
      return this.optionIds.indexOf(value);
    }
  }
};
</script>

<style lang="scss" scoped>
.group-total {
  max-width: 100%;
  width: 240px;
  .text-total {
    font-size: 1.125rem;
  }
}
</style>
